/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react"
import { ReactSession } from 'react-client-session'
import Swal from "sweetalert2"
// formik components
import { Formik, Form } from "formik"
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import SuiButton from "components/SuiButton"
import SuiAlert from "components/SuiAlert"
// Soft UI Dashboard PRO React example components
import RecruiterDashboardLayout from "examples/LayoutContainers/RecruiterDashboardLayout"
// Wizard page components
import OnePage from "layouts/applications/recruiter-newjob/components/OnePage"
// import Basic from "layouts/applications/recruiter-newjob/components/Basic"
// import Description from "layouts/applications/recruiter-newjob/components/Description"
// import Experience from "layouts/applications/recruiter-newjob/components/Experience"
// import Questions from "layouts/applications/recruiter-newjob/components/Questions"
// import Salary from "layouts/applications/recruiter-newjob/components/Salary"
import validations from "layouts/pages/users/new-user/schemas/validations"
import form from "layouts/pages/users/new-user/schemas/form"
import initialValues from "layouts/pages/users/new-user/schemas/initialValues"
import { stripHtml } from "utils/commonFunctions"

function Wizard() {
  // Validation
  ReactSession.setStoreType("localStorage");
  const token = ReactSession.get("token");
  const searchParams = new URLSearchParams(window.location.search);
  const jobId = searchParams.get('id');
  let lastApply = ReactSession.get("lastApply");
  if (!lastApply) {
    lastApply = {};
  }
  let skillArray = [];

  const [activeStep, setActiveStep] = useState(0);
  const [firstView, setFirstView] = useState(true);
  const [showBeforeUnload, setShowBeforeUnload] = useState(true)

  const [isSalaryBasedOnExperience, setIsSalaryBasedOnExperience] = useState(false)
  const [showMaxSalary, setShowMaxSalary] = useState(true)
  const [fixedSalary, setFixedSalary] = useState(false)

  const [showSubmit, setShowSubmit] = useState(false)

  const [sessionInterests, setSessionInterests] = useState({})

  /* COMMITMENT */
  const [selectedRoleCommitment, setSelectedRoleCommitment] = useState(lastApply?.commitment)

  const [commitmentOptions, setCommitmentOptions] = useState([])

  const fetchInterests = async () => { // eslint-disable-line
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/api/interests/get/`)
      const data = await res.json()
      const interestsObj = { date: new Date(), data: data.data }
      sessionStorage.setItem('interests', JSON.stringify(interestsObj))
      return interestsObj
  
    } catch (err) { console.error('fetchInterests error', err) }
  }

  useEffect(() => {
    document.title = `New Job | TECLA`;
    if (jobId) {
      document.title = `Edit Job | TECLA`;
    }
    
    setTimeout(() => {
      setShowSubmit(true)
    }, 3000);

    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = fetchInterests()
    setSessionInterests(interests)
  }, []);

  const getCommitmentOpts = async () => {
    let interests = sessionStorage.getItem('interests')
    if (interests) interests = JSON.parse(interests)
    else interests = await fetchInterests()

    const optionsArr = []
    interests?.data?.commitment?.forEach(opt => optionsArr?.push({ value: opt?.id, label: opt?.name }))
    setCommitmentOptions(optionsArr)
  }

  useEffect(() => {
    if (commitmentOptions.length === 0) getCommitmentOpts()
  }, [])

  /* CONTRIES */
  const [selectedCountryTags, setSelectedCountryTags] = useState(["Anywhere in LATAM", "United States"]);
  const [fullCountriesList, setFullCountriesList] = useState([]);

  const getCountriesList = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/jobCountries/`)
      .then(async response => {
        const data = await response.json()
        setFullCountriesList(data?.data?.countries)
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => {
    if (fullCountriesList.length === 0) getCountriesList()
  }, [])

  /* ======== */
  
  // const steps = ["Basic Info", "Description", "Experience", "Questions", "Salary & Locations"];
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  let validPost = true;

  // const stepsLength = steps.length - 1;
  const isLastStep = activeStep === 0;

  const handleNext = () => setActiveStep(activeStep + 1); // eslint-disable-line
  const handleBack = () => {
    setFirstView(false);
    setActiveStep(activeStep - 1);
  };

  // Optional job tags
  const [tagOptions, setTagOptions] = useState([])
  const [selectedTagsNames, setSelectedTagsNames] = useState([])

  const fetchTagOptions = () => {
    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/getJobTags/`, {
      headers: { "Authorization": `Token ${token}` }
    })
      .then(async response => {
          const data = await response.json()

          if (data.success) {
            const optionsArr = []
            data.data.forEach(opt => optionsArr.push({ value: opt.id, label: opt.name }))
            setTagOptions(optionsArr)
          }
      })
      .catch(error => console.error('There was an error!', error))
  }

  useEffect(() => fetchTagOptions(), [])

  // Benefits
  const [selectedRoleBenefitsIds, setSelectedRoleBenefitsIds] = useState([])

  function getStepContent(stepIndex, formData, ifFirstView) {
    return <OnePage
            formData={formData}
            firstView={ifFirstView}
            tagOptions={tagOptions}
            selectedTagsNames={selectedTagsNames}
            setSelectedTagsNames={setSelectedTagsNames}
            setIsSalaryBasedOnExperience={setIsSalaryBasedOnExperience}
            commitmentOptions={commitmentOptions}
            setCommitmentOptions={setCommitmentOptions}
            setSelectedRoleCommitment={setSelectedRoleCommitment}
            selectedRoleBenefitsIds={selectedRoleBenefitsIds}
            setSelectedRoleBenefitsIds={setSelectedRoleBenefitsIds}
            isSalaryBasedOnExperience={isSalaryBasedOnExperience}
            skillLevels={selectedCountryTags}
            setSkillLevels={setSelectedCountryTags}
            fullCountriesList={fullCountriesList}
            selectedRoleCommitment={selectedRoleCommitment}
            showMaxSalary={showMaxSalary}
            setShowMaxSalary={setShowMaxSalary}
            fixedSalary={fixedSalary}
            setFixedSalary={setFixedSalary}
          />;
  }

  // function getStepContent(stepIndex, formData, ifFirstView) {
  //   switch (stepIndex) {
  //     case 0:
  //       return <Basic
  //                 formData={formData}
  //                 firstView={ifFirstView}
  //                 tagOptions={tagOptions}
  //                 selectedTagsNames={selectedTagsNames}
  //                 setSelectedTagsNames={setSelectedTagsNames}
  //                 setIsSalaryBasedOnExperience={setIsSalaryBasedOnExperience}
  //                 commitmentOptions={commitmentOptions}
  //                 setCommitmentOptions={setCommitmentOptions}
  //                 setSelectedRoleCommitment={setSelectedRoleCommitment}
  //                 selectedRoleBenefitsIds={selectedRoleBenefitsIds}
  //                 setSelectedRoleBenefitsIds={setSelectedRoleBenefitsIds}
  //               />;
  //     case 1:
  //       return <Description formData={formData} />;
  //     case 2:
  //       return <Experience formData={formData} />;
  //     case 3:
  //       return <Questions formData={formData} />;
  //     case 4:
  //       return <Salary
  //               formData={formData}
  //               isSalaryBasedOnExperience={isSalaryBasedOnExperience}
  //               setIsSalaryBasedOnExperience={setIsSalaryBasedOnExperience}
  //               skillLevels={selectedCountryTags}
  //               setSkillLevels={setSelectedCountryTags}
  //               fullCountriesList={fullCountriesList}
  //               selectedRoleCommitment={selectedRoleCommitment}
  //               showMaxSalary={showMaxSalary}
  //               setShowMaxSalary={setShowMaxSalary}
  //             />;
  //     default:
  //       return null;
  //   }
  // }

  function showError(message) {
    let text = message;
    if (!text) {
      text = "Please fill all the fields in this section before continuing";
    }
    let timerErrorid = "";
    $(".alertErrorBox div div").text(text);
    $(".alertErrorBox").show();
    $('html, body').animate({
      scrollTop: $(".alertErrorBox").offset().top
    }, 100);
    if (timerErrorid) {
      clearTimeout(timerErrorid);
    }
    timerErrorid = setTimeout(() => {
      $(".alertErrorBox").hide();
    }, 4000);
  }

  function showSuccess(isUpdate) {
    let swalTitle = "Job created successfully.";
    let swalText = "This job will be In-Review and will need to be activated for it to be displayed to candidates";
    if (isUpdate) {
      swalTitle = "Job updated successfully."
      swalText = "";
    }
    Swal.fire({
      icon: "success",
      title: swalTitle,
      text: swalText,
    });
    let timerSuccessid = "";
    if (timerSuccessid) {
      clearTimeout(timerSuccessid);
    }
    timerSuccessid = setTimeout(() => {
      window.location.replace(`/r/dashboard`);
    }, 5000);
  }

  function getSkillAndLevel(skillString) {
    const interests = {...sessionInterests}
    const skills = interests?.data?.skills;
    const skill = skillString.substring(skillString.indexOf("-") - 1, 0).trim();
    const level = Number(skillString.substring(skillString.indexOf(" - ") + 2, skillString.length));
    let data = null;
    // eslint-disable-next-line
    $.each(skills, function (i, val) {
      if (skill === val.name) {
        data = { "level": level, "skill": { "id": val.id, "name": val.name } };
      }
    });

    return data;
  }

  function updateUser() {
    let errorMessage = "";
    if ($("form .MuiTypography-caption:visible").text().length === 0 && validPost) {
      validPost = false;
      let handleNextIsValid = true;
      lastApply = { ...ReactSession.get("lastApply") };
      if (!lastApply) {
        lastApply = {};
      }
      // if (activeStep === 0) {
      lastApply.name = $("input[name=name]").val();
      lastApply.subtitle = $("input[name=subtitle]").val();
      lastApply.hiringMethod = $("input[name=hiringMethod]").val();
      lastApply.location = $("input[name=location]").val();
      lastApply.seniority = $("input[name=seniority]").val();
      lastApply.category = $("input[name=category]").val();

      if ($("input[name=company]").val()) {
        lastApply.companyId = Number($("input[name=company]").val());
      }
      if (selectedRoleCommitment) lastApply.commitment = selectedRoleCommitment

      if (!lastApply.name || !lastApply.location || !lastApply.seniority || !lastApply.category || !lastApply.companyId || !lastApply.commitment) {
        handleNextIsValid = false;
      }

      // } else if (activeStep === 1) {
      lastApply.companyDescription = $("[data-name=description]").attr("data-bio");
      lastApply.description = $("[data-name=description]").attr("data-html");

      if (!stripHtml(lastApply.description)) {
        handleNextIsValid = false;
      }

      // Job tags
      const tagsArr = []
      selectedTagsNames.forEach(name => {
        const selectedTagId = tagOptions.filter(opt => opt.label === name)[0].value
        tagsArr.push(selectedTagId)
      })
      lastApply.tags = tagsArr

      lastApply.benefits = selectedRoleBenefitsIds

      if (lastApply.description === "<p><br></p><p><br></p><p><br></p>") {
        handleNextIsValid = false;
      }
      if (lastApply.description.length >= 15000) {
        handleNextIsValid = false;
        errorMessage = "Your description cannot have more than 15000 characters";
      }
      if (lastApply.description.length > 5000 || lastApply.companyDescription.length > 5000) {
        handleNextIsValid = false;
        errorMessage = "Max length for descriptions is 5000 characters";
      }

      // } else if (activeStep === 2) {
      if ($(".requiredBox .react-tag-input__tag__content:visible").length) {
        skillArray = [];
        // eslint-disable-next-line
        $.each($(".requiredBox .react-tag-input__tag__content:visible"), function (i, val) {
          const skill = getSkillAndLevel($(val).text());
          if (skill) {
            skillArray.push(skill);
          }
          if (skillArray.length) {
            lastApply.requiredSkills = skillArray;
          }
        });
      }

      if (!lastApply.requiredSkills || !lastApply.requiredSkills.length) {
        handleNextIsValid = false;
      }

      if ($(".optionalBox .react-tag-input__tag__content:visible").length) {
        skillArray = [];
        // eslint-disable-next-line
        $.each($(".optionalBox .react-tag-input__tag__content:visible"), function (i, val) {
          const skill = getSkillAndLevel($(val).text());
          if (skill) {
            skillArray.push(skill);
          }
          if (skillArray.length) {
            lastApply.optionalSkills = skillArray;
          }
        });
      } else {
        lastApply.optionalSkills = [];
      }

      // } else if (activeStep === 3) {
      lastApply.questions = [];
      // eslint-disable-next-line
      $.each($(".questionBox input:visible"), function (i, val) {
        if ($(val).val()) {
          lastApply.questions.push({ "title": $(val).val() });
        }
      });
      // } else if (activeStep === 4) {

      /* COUNTRIES */

      // Si se selecciona "Anywhere in Latam" y "United States", se envía array vacío
      // Si se selecciona solo "Anywhere in Latam", se envía lista de países de Latam
      // Si no, se envía solo el registro de los países seleccionados
      
      if (selectedCountryTags.length === 2 && selectedCountryTags.includes('Anywhere in LATAM') && selectedCountryTags.includes('United States')) {
        lastApply.countries = []
      }
      else if (selectedCountryTags.length === 1 && selectedCountryTags.includes('Anywhere in LATAM')) {
        const selectedCountriesArr = []

        fullCountriesList.forEach(country => {
          if (country.name !== 'United States') selectedCountriesArr.push({ name: country.name, id: country.id })
        })

        lastApply.countries = selectedCountriesArr
      }
      else {
        const selectedCountriesArr = []

        fullCountriesList.forEach(country => {
          if (selectedCountryTags.includes(country.name)) selectedCountriesArr.push({ name: country.name, id: country.id })
        })

        lastApply.countries = selectedCountriesArr
      }
      
      if (!selectedCountryTags.includes('Anywhere in LATAM') && (!lastApply.countries || !lastApply.countries.length)) {
        handleNextIsValid = false;
      }
      /* ============= */

      lastApply.minSalary = $(".salaryRange").attr("data-min");
      lastApply.maxSalary = $(".salaryRange").attr("data-max");
      // Stores wether the job has an hourly rate or not. Freelance positions have hourly rate.
      const hasHourlyRate = selectedRoleCommitment?.id === 3

      if (!lastApply.minSalary || lastApply.minSalary === "0") {
        lastApply.minSalary = hasHourlyRate ? 30 : 5000;
      }
      if (!lastApply.maxSalary || lastApply.maxSalary === "20000") {
        lastApply.maxSalary = hasHourlyRate ? 50 : 10000;
      }

      // If not show max salary, send min salary and max salary is top of the range 
      if (!showMaxSalary || fixedSalary) lastApply.maxSalary = hasHourlyRate ? 100 : 20000;

      // }

      lastApply.salaryBasedOnExperience = isSalaryBasedOnExperience;
      lastApply.includeMaxSalary = showMaxSalary;
      lastApply.fixedSalary = fixedSalary;
      
      ReactSession.set("lastApply", lastApply);

      validPost = true;
      let method = 'POST';
      // if (handleNextIsValid && activeStep !== stepsLength) {
      //   handleNext();
      // } else if (handleNextIsValid && activeStep === stepsLength) {
      if (handleNextIsValid) {
        let isUpdate = false;
        let recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/recruiters/recruiterCreateJob/`;
        if (jobId) {
          recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/companies/${jobId}/companyUpdateJob/`;
          method = "PATCH";
          isUpdate = true;
        }

        if (!lastApply.addAsFavorite) lastApply.addAsFavorite = false;

        const requestMetadata = {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
          },
          body: JSON.stringify(lastApply)
        };
        fetch(recipeUrl, requestMetadata)
          .then(res => res.json())
          .then(response => {
            validPost = true;
            if (response.success) {
              ReactSession.set("lastApply", {});
              showSuccess(isUpdate);
              setShowBeforeUnload(false);
            }
          });
      } else if (!handleNextIsValid) {
        $.each($(".validateField"), function () { // eslint-disable-line
          if ($(this).find(".ql-editor").length) { // eslint-disable-line
            if (!$(this).find(".ql-editor").text()) { // eslint-disable-line
              $(this).addClass("error");
            }
          } else if ($(this).find("input[type=text]").length) {
            if ((!$(this).find("input[type=hidden]").length || !$(this).find("input[type=hidden]").val()) && 
                (!$(this).find("input[type=text]").val() || !$(this).find("input[type=text]").val().length)) { // eslint-disable-line
              $(this).addClass("error");
            }
          } else if($(this).find(".react-tag-input").length) {
            if (!$(this).find(".react-tag-input > div").length) { // eslint-disable-line
              $(this).find(".react-tag-input").addClass("error");
            }
          }
        })
        showError(errorMessage)
     }
    }
  }

  // eslint-disable-next-line
  window.onbeforeunload = function(e) {
    if (showBeforeUnload) {
      return "Changes you made may not be saved.";
    }
  };

  return (
    <RecruiterDashboardLayout>
      <SuiBox pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SuiBox mt={6} mb={1} textAlign="center">
              <SuiBox mb={1}>
                <SuiTypography variant="h3" fontWeight="bold">{ jobId ? ("Edit Job") : ("Create a New Job") }</SuiTypography>
              </SuiBox>

              <SuiTypography variant="h5" fontWeight="regular" color="secondary">Please fill out the following details to continue</SuiTypography>
            </SuiBox>

            <SuiBox className="alertErrorBox" display="none">
              <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
            </SuiBox>
            <SuiBox className="alertSuccessBox" display="none">
              <SuiAlert color="info" className="alertSuccess">Application submitted!</SuiAlert>
            </SuiBox>
            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidation}
                  >
                    {({ values, errors, touched, issubmitting }) => (
                      <Form id={formId} autoComplete="off" type="POST" actions="#" encType="multipart/form-data">
                        <SuiBox className="moonBox">
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          }, firstView)}
                          <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            {activeStep === 0 ? (
                              <SuiBox />
                            ) : (
                              <SuiButton variant="outlined" color="info" onClick={handleBack} issubmitting={issubmitting} className="changeStep">
                                back
                              </SuiButton>
                            )}
                            { showSubmit ? (
                              <SuiButton
                                variant="gradient"
                                color="info"
                                onClick={updateUser}
                                className="changeStep nextStep"
                              >
                                {isLastStep ? "submit" : "next"}
                              </SuiButton>
                            ) : null }
                          </SuiBox>
                        </SuiBox>
                      </Form>
                    )}
                  </Formik>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </RecruiterDashboardLayout>
  );
}

export default Wizard;
